/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-friends:before { content: "\f100"; }
.flaticon-chip:before { content: "\f101"; }
.flaticon-like:before { content: "\f102"; }
.flaticon-award:before { content: "\f103"; }
.flaticon-support:before { content: "\f104"; }
.flaticon-correct:before { content: "\f105"; }
.flaticon-calendar:before { content: "\f106"; }
.flaticon-customer-service:before { content: "\f107"; }
.flaticon-coin:before { content: "\f108"; }
.flaticon-info:before { content: "\f109"; }
.flaticon-goal:before { content: "\f10a"; }
.flaticon-left-arrow:before { content: "\f10b"; }
.flaticon-next-1:before { content: "\f10c"; }
.flaticon-placeholder:before { content: "\f10d"; }
.flaticon-clock:before { content: "\f10e"; }
.flaticon-telephone:before { content: "\f10f"; }